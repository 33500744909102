import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import Service from "../components/Service";
import {PrintOrders} from "../components/PrintOrders";
import PrintPreview from "../views/PrintPreview";
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink, CSVDownload } from "react-csv";
import Select from '../components/Select';
import DateTime from '../components/DateTime';
var soap = require('soap-everywhere');

const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);

function parseDate(date){
    let d = date.getFullYear();
    let month = date.getMonth() + 1;
    if(month < 10) {month = '0' + month}
    let day = date.getDate();
    if(day < 10) {day = '0' + day}
    d += '-' + month + '-' + day;
    return d;
}

let stores = [
    {id: 0, name: "Sve prodavnice"},
    {id: 1, name: "Harmonija"},
    {id: 2, name: "Stela"},
    {id: 3, name: "Publikpraktikum"}
]

function ptest (){
        return new Promise(function(resolve) {
            setInterval(resolve, 3000);
        });
    }

const API = process.env.REACT_APP_API;

const csvData = [
    ["ReferenceID"|"SBranchID"|"SName"|"SAddress"|"STownID"|"STown"|"SCName"|"SCPhone"|"PuBranchID"|"PuName"|"PuAddress"|"PuTownID"|"PuTown"|"PuCName"|"PuCPhone"|"RBranchID"|"RName"|"RAddress"|"RTownID"|"RTown"|"RCName"|"RCPhone"|"DlTypeID"|"PaymentBy"|"PaymentType"|"BuyOut"|"BuyOutAccount"|"BuyOutFor"|"Value"|"Mass"|"ReturnDoc"|"Packages"|"Note"|"Content"],
//   ["firstname", "lastname", "email"],
//   ["Ahmed", "Tomi", "ah@smthing.co.com"],
//   ["Raed", "Labes", "rl@smthing.co.com"],
//   ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];

    // function getLastSelected(orders){
    //     oders.map
    // }

const customTrim=(n)=>{
        return n.toString().trim().replace(/\s\s+/g, ' ');
    }

function getStoreName(storeId){
    let stores={
        1: 'harmonija', 
        2: 'stela', 
        3: 'publik'};
    return stores[storeId];
}

var currentSroll = 0;

class Orders extends Component {

    constructor(props) {
    super(props);
    this.state={
      orders:[],
      numberOfOrders: 0,
      pageButtons:[],
      filter: '',
      page: 1,
      selectedOrders: [],
      allSelected: false,
      csvData: csvData,
      csvReady: false,
      perPage: 50,
      lastSelected: null,
      ordersOpacity: false,
      onlySent: false,
      fromDate: '',
      toDate: '',
      filterType: '',
      status: 'all',
      preorder: 'all',
      storeId: 0,
      storeName: ''
    }
    this.scrollRef = React.createRef();
  }

  perPage= 50;
  currentPage = 1;

//   componentDidUpdate(prevProps, prevState, snapshot) {
//     // If we have a snapshot value, we've just added new items.
//     // Adjust scroll so these new items don't push the old ones out of view.
//     // (snapshot here is the value returned from getSnapshotBeforeUpdate)
//     console.log('ss',snapshot)
//     if (snapshot !== null) {
//       const list = this.scrollRef.current;
//       list.scrollTop = snapshot;
//     }else{
//         this.scrollRef.current.scrollTop = 0;
//     }
//   }

//   getSnapshotBeforeUpdate(prevProps, prevState) {
//       console.log('test');
//     // Are we adding new items to the list?
//     // Capture the scroll position so we can adjust scroll later.
//     //if (prevProps.list.length < this.props.list.length) {
//         if(this.scrollRef){
//             console.log('current', this.scrollRef.current.scrollTop);
//             const list = this.scrollRef.current;
//             return list.scrollHeight - list.scrollTop;
//         }
//     //}
//     return null;
//   }
  handleScroll=()=>{
      console.log(currentSroll);
      if(this.scrollRef.current.scrollTop > 0){
        currentSroll = this.scrollRef.current.scrollTop;
      }
  }

  componentDidMount(){
    
      PrintOrders.addOrder(12);
      //'po: ', PrintOrders.orders);
        if(parsed.filter){
            this.setState({filter: parsed.filter},()=>{
                this.updateOrders();
            })
        }else{
            this.updateOrders();
        }
        if(this.props.match.params.page){
            this.currentPage = this.props.match.params.page;
        }else{
            this.currentPage = 1;
        }

        /** set per page from local storage */
        if(localStorage.getItem('cms_pp_per_page')){

            this.setState({perPage: parseInt(localStorage.getItem('cms_pp_per_page'))},()=>{
                this.updateOrders();
            })
        }else{
            this.setState({perPage: this.perPage},()=>{
                this.updateOrders();
            })
        }
  }

  changeStore = (id) => {
    let storeName = '';
    stores.map(item => {
        if(item.id == id){
            storeName = item.name;
        }
    })
    this.setState({storeId: id, storeName: storeName, page: 1 },()=> {
        console.log("page", this.state.page);
        this.updateOrders();
    })
}

  
  handlePerPage=(event)=>{
      this.setState({perPage: event.target.value},()=>{
          if(this.state.perPage > 0){
            //this.perPage = this.state.perPage;
            localStorage.setItem('cms_pp_per_page', this.state.perPage);
            //console.log('ls', localStorage);
            this.updateOrders();
          }
      })
  }
 

  createPageButtons(n){
   
    let b = [];

    for (var i=0; i<n ; i++ ){
      b.push({name: i+1});
    }
    this.setState({pageButtons: b});

  }

  componentWillReceiveProps(nextProps){
    this.updateOrders();
  }

  updateOrders=()=>{
      // reset orders
      //this.setState({orders: []})
    Service.get("/orders/get",{
            page: this.state.page, 
            perPage:this.state.perPage, 
            filter: this.state.filter, 
            onlySent: this.state.onlySent, 
            from: this.state.fromDate ? parseDate(this.state.fromDate) : null, 
            to: this.state.toDate ? parseDate(this.state.toDate) : null, 
            type: this.state.filterType,
            status: this.state.status,
            preorder: this.state.preorder,
            storeId: this.state.storeId 
        },(res)=>{
      //let o = this.state.orders;

        res.data.orders.map(function(item, index){
            item.checked = false;
            item.key = index;
        })
      
      this.setState({orders:res.data.orders, numberOfOrders: res.data.totalNumberOfOrders},()=>{
        // this.createPageButtons(this.numberOfPages());
        console.log(currentSroll);
        this.scrollRef.current.scrollTop = currentSroll;
        
      })
    })

  }
   page(){
     if(this.props.match.params.page){
       return parseInt(this.props.match.params.page);
     }
     return parseInt(1);
   }

   numberOfPages(){
     return Math.ceil(parseInt(this.state.numberOfOrders)/this.perPage);
   }

   getNumberOfPages=()=>{
       return Math.ceil(parseInt(this.state.numberOfOrders)/this.perPage);
   }


   pageUp=()=>{
        let cp = this.page();
        cp += 1;
        if(cp > this.numberOfPages()){
            cp = 1;
        }
        if(this.state.filter){
            window.location.replace("/orders/"+cp+"?filter="+this.state.filter)
        }else{
            window.location.replace("/orders/"+cp)
        }
    }

    pageDown=()=>{
        let cp = this.page();
        cp -= 1;
        if(cp < 1){
            cp = this.numberOfPages();
        }
        if(this.state.filter){
            window.location.replace("/orders/"+cp+"?filter="+this.state.filter)
        }else{
            window.location.replace("/orders/"+cp)
        }
    }

    goToPage=(event)=>{
      //console.log(event.target.id);
        if(this.state.filter){
            window.location.replace("/orders/"+event.target.id+"?filter="+this.state.filter)
        }else{
            window.location.replace("/orders/"+event.target.id)
        }
      
    }

    searchByName=()=>{
        //console.log(this.state.filter);
        // window.location.replace("/orders?filter="+this.state.filter);
        this.updateOrders();
    }
    

    handleFilter=(event)=>{
        this.setState({filter: event.target.value},()=>{
           // console.log(this.state.filter)
        });
    }

    handleFocusKey=(event)=>{
        if (event.key === 'Enter') {
            this.searchByName();
        }
    }

    selectAllOrders = event =>{
        let so = [];
        let o = this.state.orders;
        if(!this.state.allSelected){
            o.map(function(item, index){
                if(item.delivery_status === '0' && item.status === '1'){
                    item.checked = true;
                    if(item.checked){
                        so.push(item.id)
                    }
                }
            })
            this.setState({allSelected: true});
        }else{
            o.map(function(item, index){
            
                item.checked = false;
            })
            this.setState({allSelected: false});
        }

        this.setState({orders: o, selectedOrders: so},()=>{

            console.log(this.state.selectedOrders);
        });
    }

    sendOrdersToCsv=()=>{

            Service.get('/orders/dexpressOrders', {orders: this.state.selectedOrders},(res)=>{
                let c = [];
                c.push(["ReferenceID","SBranchID","SName","SAddress","STownID","STown","SCName","SCPhone","PuBranchID","PuName","PuAddress","PuTownID","PuTown","PuCName","PuCPhone","RBranchID","RName","RAddress","RTownID","RTown","RCName","RCPhone","DlTypeID","PaymentBy","PaymentType","BuyOut","BuyOutAccount","BuyOutFor","Value","Mass","ReturnDoc","Packages","Note","Content"]);
                //'data', c);
                if(res.success){
                    res.data.orders.map(function(row){
                        let ps = [];
                        row.map((item)=>{
                           ps.push(customTrim(item)); 
                        })
                        c.push(ps);
                    })
                    
                    this.setState({csvData: c, csvReady: true},()=>{
                        //console.log('csv data', this.state.csvData);
                        //this.updateOrders();    
                    });
                }else{
                    alert('Sve dabrane porudzbine su sve vec poslate! Odaberi bar jednu neposlatu porudzbinu ili promeni status porudzbine')
                }
                //console.log(res);
                //this.updateOrders();
            })
    }

    sendOrdersToCsv2=(event)=>{

        let buyOut = event.target.getAttribute('data-buy-out');
        Service.get('/orders/dexpressOrders2', {orders: this.state.selectedOrders, buyOut},(res)=>{
            if(res.success){
                let sentLength= "";
                let unsentLength= "";
            
                if(res.sent.length > 0){
                    sentLength = res.sent.length;
                }else{
                    sentLength = 0;
                }

                if(res.unsent.length > 0){
                    unsentLength = res.unsent.length;
                }else{
                    unsentLength = 0;
                }

                alert('Poslato: '+sentLength+'\nNije poslato: '+unsentLength)
                this.updateOrders(); 
            }else{
                alert('Sve dabrane porudzbine su sve vec poslate! Odaberi bar jednu neposlatu porudzbinu ili promeni status porudzbine')
            }
        })
    }

    ordersMerge=()=>{
        if(this.state.selectedOrders.length <= 1){
            alert('Morate da odaberete više od jedne porudžbine')
        }else{
            Service.get('/orders/merge', {orders: this.state.selectedOrders},(res)=>{
                if(res.success){
                    //this.updateOrders();
                    this.props.history.push(`/order/${this.state.selectedOrders[0]}?save=1&action=1`);
                    //return (<Redirect to={`/order/${this.state.selectedOrders[0]}?save=1`} />);
                }else if(res.message){
                    alert(res.message)
                }
            })
        }
    }

    sendCheckedOrders=()=>{
        Service.get('/orders/setStatusPublikDelivered', {orders: this.state.selectedOrders},(res)=>{

            this.updateOrders();
        })
    }



    selectOrder = event => {
        
        let so = [];
        let orders = this.state.orders;
        let sso = [];

        if (event.shiftKey) {

            // let pso = [];
            // orders.map(function(item, index){
            //     if(item.checked){
            //         pso.push(index);
            //     }

            // });
            
            // get direction
            let selectedIndex = event.target.getAttribute('data-index');
            let dir = selectedIndex > this.state.lastSelected ? true : false;
            console.log('dir', dir, selectedIndex, this.state.lastSelected);

            orders.map(function(item, index){
                if(dir){
                    //if(item.key >= this.state.lastSelected && item.key <= selectedIndex && item.status === '1' && !item.preorder && item.delivery_status !== '7' && item.delivery_status !== '8'){
                    if(item.key >= this.state.lastSelected && item.key <= selectedIndex && item.status === '1' && item.delivery_status !== '7' && item.delivery_status !== '8'){
                        item.checked = true;
                    }
                }else{
                    if( item.key >= selectedIndex && item.key <= this.state.lastSelected && item.status === '1' && item.delivery_status !== '7' && item.delivery_status !== '8'){
                        item.checked = true;                      
                    }
                }

                if(item.checked){
                    so.push(item.id);
                }


            },this)


        } else {

console.log('aa', event.target.id)
            orders.map(function(item, index){
                if(item.id.toString() === event.target.id.toString()){
                    console.log('found')
                    item.checked = !item.checked;
                    if(item.checked){
                        this.setState({lastSelected: index},()=>{
                            console.log('lsel',this.state.lastSelected)
                        });
                    }
                    
                }
                if(item.checked){
                    so.push(item.id)
                }
            },this)
        }
       
        this.setState({orders: orders, selectedOrders: so},()=>{
            console.log('so', this.state.selectedOrders);
        });

           // console.log(event.target.checked, event.target.id);
        // if(event.target.checked){
        //     o.push(id: event.target.id, isChecked:);
        // }else{
        //     o.map((item, index)=>{
        //         if(item == event.target.id){
        //             o.splice(index, 1);
        //         }
        //     })
        // }

        // o.forEach(order => {
        // if (order.id === event.target.id)
        //     order.isChecked = event.target.checked
        // })
        // this.setState({ selectedOrders: o },()=>{
        //     console.log(this.state.selectedOrders);
        // })
    }

    changeOrderDeliveryStatus = (event) => { 
        this.setOrderDeliveryStatus(event.target.id, event.target.value);
    }
    
    setOrderDeliveryStatus = (orderId, deliveryStatus) => {
        Service.get("/orders/orderDeliveryStatus",{
            orderId: orderId,
            deliveryStatus: deliveryStatus
            },(res)=>{
                if(res.success){
                    this.updateOrders();
                }
        });
    }

    changePage = page => {

        if(page.selected){
            this.setState({page: page.selected + 1},()=>{
                this.updateOrders();
            })
        }else{
            this.setState({page: 1},()=>{
                this.updateOrders();
            })
        }
        // let link;
        // if(page.selected){
        //     link = `/orders/${page.selected + 1}`;
        //    // window.location.href = link;
        // }else{
        //     link = `/orders`;
            
        // }
        // window.location.href = link;
    }

    deleteOrder=(event)=>{
        let orderId = event.target.getAttribute('data-order-id');
        if (window.confirm("Da li ste sigurni da želite da obrišete porudžbinu?")) {
            Service.get('/orders/deleteorder', {orderId: orderId},(res)=>{
                if(res.success){
                    this.updateOrders();
                }
            })
        }
    }

    addShipment=(event)=>{
        let orderId = event.target.getAttribute('data-order-id');
        let shippingAddress = event.target.getAttribute('data-shipping-address');

        let street = shippingAddress.substring(0, shippingAddress.lastIndexOf(" ") + 1);
        let sv = street.replace(/\s+$/, '');
        let streetNo = shippingAddress.substring(shippingAddress.lastIndexOf(" ") + 1, shippingAddress.length);
       
        Service.get('/orders/addshipment',{id: event.target.id},(res)=>{
            
        });
    }

    handleOrderOpacity=()=>{
        this.setState({ordersOpacity: !this.state.ordersOpacity});
    }

    handleOnlySent=()=>{
        this.setState({onlySent: !this.state.onlySent}, ()=>{
            this.updateOrders();
        });
    }

    handleFromDateChange = date => {
        console.log(parseDate(date));
        this.setState({
          fromDate: date
        },()=>{
            this.updateOrders();
        });
    }; 

    handleToDateChange = date => {
        this.setState({
          toDate: date
        },()=>{
            this.updateOrders();
        });
    }; 

    handleFilterType=(event)=>{
        this.setState({filterType: event.target.value},()=>{
            this.updateOrders();
        })
    }

    handleStatus=(event)=>{
        this.setState({status: event.target.value},()=>{
            this.updateOrders();
        })
    }

    handlePreorder=(event)=>{
        this.setState({preorder: event.target.value},()=>{
            this.updateOrders();
        })
    }

    syncErpOrders=(event)=>{
        let orderId = event.target.getAttribute('data-order-id');
        // Service.get(`/orders/addErpOrder?id=${orderId}`, (res)=>{
        //     if(res.success){
        //         alert(`porudzbina ${orderId} je uspesno poslata u ERP`);
        //         this.updateOrders();
        //     }else{
        //         alert(res.error);
        //     }
        // })
        Service.syncErpOrders(orderId, (res)=>{
            console.log(res);
            if(res.success){
                alert(`porudzbina ${orderId} je uspesno poslata u ERP`);
                this.updateOrders();
            }else{
                alert(res.error);
            }
        });
    }

    setCcToPp=(event)=>{
        let orderId = event.target.getAttribute('data-order-id');

        if(window.confirm("Da li zelite da prebacite porudzbinu sa Kreditne kartice na Pouzece?")) { 
            Service.get('/orders/ccToPp', {orderId: orderId},(res)=>{
                if(res.success){
                    this.updateOrders();
                }
            })
        }
    }

    createDuplicatedFilter=(event)=>{

        var today = new Date();
        var to = today.getFullYear()+'-'+('0'+(today.getMonth()+1)).slice(-2)+'-'+('0'+today.getDate()).slice(-2);
        today.setDate(today.getDate() - 15);
        var from = today.getFullYear()+'-'+('0'+(today.getMonth()+1)).slice(-2)+'-'+('0'+today.getDate()).slice(-2);
        console.log(from, to);
        this.setState({filter: event.target.getAttribute('data-name')}, ()=>{
            this.updateOrders();
        })
    }

    refund = (event) => {
        let orderId = event.target.getAttribute('data-order-id');
        if(prompt('STORNIRANJE!!!', 'Unesi šifru') == 'krcko321'){
            console.log(event.target.getAttribute('data-order-id'));
            Service.get('/orders/refund', {orderId}, (res)=>{
                if(res.success){
                    this.updateOrders();
                }
                if(res.error){
                    alert('doslo je do greske');
                }
            });
        }else{
            alert('Šifra je netačna!');
        }
    }

  render() {
    return (
      <div>
          <div>
          <h1>Porudžbine</h1>
          {/* <Link to="/print-preview">Štampaj</Link> */}
      
          <PrintPreview orders={this.state.selectedOrders} />
          <button onClick={this.sendCheckedOrders} >Obeleži poslato</button>
          <button onClick={this.sendOrdersToCsv} >Pošalji Dexpress CSV</button>
          <button onClick={this.sendOrdersToCsv2} >Send Dexpress Keš</button>
          <button data-buy-out={1} onClick={this.sendOrdersToCsv2} >Send Dexpress Račun</button>
          <button onClick={this.ordersMerge} >Spoji porudžbine</button>
          
          
            {this.state.csvReady === true &&
                <CSVLink 
                    data={this.state.csvData} 
                    filename={"dexpress-publikpraktikum.csv"}
                    onClick={() => {
                        setTimeout(()=>{window.location.reload()},2000); 
                    }}
                    separator={"|"}>
                        Download Dexpress Orders
                </CSVLink>
            }
          
            <div>
                {/* <div className='search-container'><input placeholder="Pretraga..." type="text" value={this.state.filter} onChange={this.handleFilter} onKeyDown={this.handleFocusKey} /><button onClick={this.searchByName}>Pretraži</button></div> */}
                <ReactPaginate
                    pageCount={this.getNumberOfPages()}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={2}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    forcePage={this.state.page - 1}
                    containerClassName={'pager-container'}
                    activeClassName={'pager-selected'}
                    //initialSelected={this.state.page}
                    onPageChange={this.changePage}
                />
                {/* <a href="/print-preview" ><button>Print preview</button></a> */}
                   
            </div>
            <section>
                <div className="quarter">
                    <label htmlFor="perPage">Po stranici: </label>
                    <input type="text" id="perPage" value={this.state.perPage} onChange={this.handlePerPage} />
                </div>
                <div className="quarter padding-left">
                    <label htmlFor="Shop">Prodavnica: </label>
                    <Select placeholder="Odaberi shop..." id='id' value='name' debug='x' options={stores} selected={this.state.storeId} onSelect={(res) => this.changeStore(res)}/>
                </div>
            </section>
            
            <button className={this.state.ordersOpacity == true ? 'button-active' : ''} onClick={this.handleOrderOpacity}>Prosvetli poslate</button>
            <button className={this.state.onlySent == true ? 'button-active' : ''} onClick={this.handleOnlySent}>Samo neposlato</button>
            
            <label htmlFor="fromDate">Od: </label>
            <DatePicker
                id="fromDate"
                selected={this.state.fromDate}
                onChange={this.handleFromDateChange}
                dateFormat={'yyyy-MM-dd'}
                showYearDropdown={true}
            />

            <label htmlFor="toDate">Do: </label>
            <DatePicker
                id="toDate"
                selected={this.state.toDate}
                onChange={this.handleToDateChange}
                dateFormat={'yyyy-MM-dd'}
                showYearDropdown={true}
            />

            {/* <label htmlFor="type">Način plaćanja: </label> */}
            {/* <select id="type" value={this.state.filterType} onChange={this.handleFilterType}>
                <option value="">Svi</option>
                <option value="cc">Karticom</option>
                <option value="pp">Pouzećem</option>
            </select> */}

            {/* <label htmlFor="type">Status: </label> */}
            {/* <select id="type" value={this.state.status} onChange={this.handleStatus}>
                <option value="all">Svi</option>
                <option value="0" >ceka na preuzimanje</option>
                <option value="3" >posiljka je preuzeta</option>
                <option value="1" >posiljka je isporucena</option>
                <option value="20" >posiljka je vracena posiljaocu</option>
            </select> */}
            {/* <label htmlFor="type">Pretprodaja: </label> */}
            {/* <select id="type" value={this.state.preorder} onChange={this.handlePreorder}>
                <option value="all">Svi</option>
                <option value="1" >Pretprodaja</option>
                <option value="0" >Bez pretprodaje</option>
            </select> */}
        </div>
        <div className="table-cont" ref={this.scrollRef} onScroll={this.handleScroll}>
            <table className='cms-table'>
                <thead>
                    {/* <tr>
                        <th>br.<input type="checkbox"  onClick={this.selectAllOrders} checked={this.state.allSelected} ></input></th>
                        <th>Porudžbina</th>
                        <th>Kupac</th>
                        <th>Napomena</th>
                        <th>Adresa Slanja</th>
                        <th>Status</th>
                        <th>Način plaćanja</th>
                        <th>Total</th>
                    </tr> */}
                    <tr>
                        <th>
                            br.<input type="checkbox"  onClick={this.selectAllOrders} checked={this.state.allSelected} ></input><br/>
                            <select id="type" value={this.state.preorder} onChange={this.handlePreorder}>
                                <option value="all">Svi</option>
                                <option value="1" >Pretprodaja</option>
                                <option value="0" >Bez pretprodaje</option>
                            </select>
                        </th>
                        <th colSpan="2">Porudžbina<br/>
                        <input placeholder="Pretraga..." type="text" value={this.state.filter} onChange={this.handleFilter} onKeyDown={this.handleFocusKey} />
                        </th>
                        {/* <th>Kupac</th> */}
                        <th>Napomena</th>
                        <th>Adresa Slanja</th>
                        <th>Status<br/>
                            <select id="type" value={this.state.status} onChange={this.handleStatus}>
                                <option value="all">Svi</option>
                                <option value="null">Bez statusa</option>
                                <option value="0" >ceka na preuzimanje</option>
                                <option value="3" >posiljka je preuzeta</option>
                                <option value="1" >posiljka je isporucena</option>
                                <option value="20" >posiljka je vracena posiljaocu</option>
                            </select>
                        </th>
                        <th>Način&nbsp;plaćanja<br/>
                        <select id="type" value={this.state.filterType} onChange={this.handleFilterType}>
                            <option value="">Svi</option>
                            <option value="cc">Karticom</option>
                            <option value="pp">Pouzećem</option>
                        </select>
                        </th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    
                {this.state.orders.map(function(item, index){
                    return(
                        <tr key={index} className={(item.dexpress_status_id === null || this.state.ordersOpacity === false ) ? '' : 'dex-taken'}>
                            {/* <p>{item.id} {item.email} {item.billing_address} {item.created_at} {item.total} {item.modified_at} {item.shop} </p>  */}
                            <td className='table-index'>{index + 1}. 
                                {/* {!item.preorder &&
                                    <fragment> */}
                                    { item.delivery_status === '0' &&
                                        <div className="checkbox-light">
                                            <input type="checkbox" id={item.id} data-index={index}  onClick={this.selectOrder} checked={item.checked} ></input>
                                        </div>
                                    }
                                    

                                    { (item.delivery_status === '1' || item.delivery_status === '2' || item.delivery_status === '3' || item.delivery_status === '7' || item.delivery_status === '8') &&
                                        <input type="checkbox" id={item.id} data-index={index} onClick={this.selectOrder} checked={item.checked} ></input>
                                    }
                                    {/* </fragment>
                                } */}
                                {item.preorder && item.delivery_status == '0' &&
                                    <fragment>
                                        <div  style={{background: 'orange', color: 'white'}}>PREORDER</div>
                                        {item.products.map((product, index)=>{
                                            return(
                                            <div style={{fontSize: '.5em'}}>{product.title}</div>
                                            )
                                        })

                                        }
                                    </fragment>
                                }
                                {item.duplicated && item.delivery_status == '0' &&
                                    <div data-name={item.name} onClick={this.createDuplicatedFilter} style={{background: 'red', color: 'white', cursor: 'pointer'}}>DUPLIRANA</div>
                                }
                                { (item.delivery_status != '0' && item.delivery_status !== '7' && item.delivery_status !== '8') &&
                                    <img src="/delivered.png" width="24" style={{display: 'inline-block'}} />
                                }
                                { (item.delivery_status === '7' || item.delivery_status === '8') &&
                                    <img src="/canceled.png" width="24" style={{display: 'inline-block'}} />
                                }
                                { item.erp_status != 1 && item.status == 1 &&
                                    <button onClick={this.syncErpOrders} data-order-id={item.id} >&gt;&gt; ERP</button>
                                }
                            </td>
                            <td>ID: <b>
                                <Link to={"/order/" + item.id} target="_blank">{item.id}</Link></b> <br/>
                                <b>{getStoreName(item.store_id)}</b><br/>
                                {item.modified_at}<br/>
                                {item.code}<br/>
                                {item.fiscalisation_data && item.fiscalisation_data.response &&
                                <b style={{fontSize: '.8em'}}>{item.fiscalisation_data.response.invoiceNumber}</b>
                                }
                                </td>
                            <td><b>{item.name}</b><br/>{item.email}<br/>Tel:{item.phone}&nbsp; </td>
                            {/* <td>{item.billing_address}<br/>{item.zip_code}  {item.city}</td> */}
                            <td style={{fontSize: '.8em'}}>{item.note}</td>
                            <td><b>{item.shipping_name}</b><br/>{item.shipping_address} <br/>{item.shipping_zip_code} {item.shipping_city}<br/>Opstina:&nbsp;{item.shipping_municipality}  </td>
                            <td>
                                { item.status === '0' &&
                                    <span className="orders-payment-status">
                                    <div className='dot' style={{background: 'orange'}}></div>Započeto
                                    </span>
                                }
                                { item.status === '1' &&
                                    <span className="orders-payment-status">
                                        <div className='dot' style={{background: 'green'}}></div>Plaćeno
                                    </span>
                                }
                                
                                <select className="orders-delivery-selector" id={item.id} value={item.delivery_status} onChange={this.changeOrderDeliveryStatus}>
                                    <option value="0" name={item.id}>Nije poslato</option>
                                    <option value="1" name={item.id}>Poslato Dexpressom</option>
                                    <option value="2" name={item.id}>Poslato pp</option>
                                    <option value="3" name={item.id}>Poslato iz Daily aplikacije</option>
                                    <option value="7" name={item.id}>Dupla porudžbina</option>
                                    <option value="8" name={item.id}>Otkazano</option>
                                </select>
                                
                                <span>
                                    <img src="/truck.png" width="20" style={{display: 'inline-block', marginRight: '4px', marginTop: '5px'}} />
                                    <span>{item.statusText}</span>
                                    <br />
                                    {parseInt(item.delivery_status) > 0 &&
                                        <div className={item.buy_out == 1 ? 'return buy-out': 'return'}>povrat: {item.buy_out == 1 ? 'račun' : 'keš'}</div>
                                    }
                                </span>
                                {item.fiscalisation_status == 1 &&
                                    <>
                                    <div style={{background: 'green', color: 'white', fontSize: '.8em', display: 'inline-block', padding: '.3em', borderRadius: '.3em'}} >fiskalizovano</div>
                                    <button onClick={this.refund} data-order-id={item.id}>Storniraj</button>
                                    </>
                                }
                                {item.fiscalisation_status == 2 &&
                                    <div style={{background: 'orange', color: 'white', fontSize: '.8em', display: 'inline-block', padding: '.3em', borderRadius: '.3em'}} >čeka fiskalizaciju</div>
                                }
                                {item.fiscalisation_status == 7 &&
                                    <div style={{background: 'darkred', color: 'white', fontSize: '.8em', display: 'inline-block', padding: '.3em', borderRadius: '.3em'}} >greska u fiskalizaciji</div>
                                }
                                {item.fiscalisation_status == 5 &&
                                    <div style={{background: 'black', color: 'white', fontSize: '.8em', display: 'inline-block', padding: '.3em', borderRadius: '.3em'}} >Stornirano</div>
                                }
                                
                            </td>
                            <td>
                                {/* <br/><br/>Način plaćanja: */}
                                { item.payment_method === "pp" &&
                                    <span>
                                        <span>Pouzećem</span>
                                        <img src="/pp.png" width="32" style={{display: 'block'}} />
                                    </span>
                                }
                                { item.payment_method === "cc" &&
                                    <span>
                                        <span>Kreditna kartica</span>
                                        <img src="/cc.png" width="32" style={{display: 'block'}}/>
                                    </span>
                                }

                                {(item.payment_method === "cc" && item.status === '0') &&
                                    <button onClick={this.setCcToPp} data-order-id={item.id}>CC &#62; PP</button>
                                }
                            </td>
                            <td><b>{item.total}</b> RSD
                            <div>
                            <img  style={{cursor: 'pointer'}} src="/delete-bin.png" onClick={this.deleteOrder} width="32" data-order-id={item.id} />
                            </div>
                            {/* <button onClick={this.addShipment} style={{backgroundColor: 'pink'}} data-order-id={item.id} data-shipping-address={item.shipping_address} >NOVI API</button> */}
                            </td>
                        </tr>
                        )
                    },this)
                }
                </tbody>
            </table>
        </div>   
      </div>
    );
  }

}

export default Orders;
